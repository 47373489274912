import actions from './actions'

const initialState = {
  loading: false,
  loanSummary: {},
  tabKey: '1',
  pendingLoanRequest: [],
  markedLoanRequest: [],
  approvedLoanRequest: [],
  processingLoanRequest: [],
  allLoanRequest: [],
  disbursedLoanRequest: [],
  ongoingLoanRequest: [],
  completedLoanRequest: [],
  repayDetail:[],
  cancelledLoanRequest: [],
  loanDetail: {},
  loanAdmin: [],
  loanGuarantor: [],
  amountRepaid: 0,
  loanAmount: 0,
  loanDetailStatus: "",
  closed: true,
  loanTransDetail: {},
  loanTransTransaction: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
