const actions = {
  SET_STATE: 'procurement/SET_STATE',
  SET_TAB_KEY: 'procurement/SET_TAB_KEY',
  GET_ZERO_PROCUREMENT_SUMMARY: 'procurement/GET_ZERO_PROCUREMENT_SUMMARY',
  GET_ZERO_PROCUREMENT_REQUEST: 'procurement/GET_ZERO_PROCUREMENT_REQUEST',
  GET_ZERO_PROCUREMENT_DETAIL: 'procurement/GET_ZERO_PROCUREMENT_DETAIL',
  GET_ZERO_PROCUREMENT_TRANSACTION: 'procurement/GET_ZERO_PROCUREMENT_TRANSACTION',
  APPROVE_DECLINE_PROCUREMENT: 'procurement/APPROVE_DECLINE_PROCUREMENT',
  GET_PROCURE_REPAY_DETAIL: 'procurement/GET_PROCURE_REPAY_DETAIL',
  GET_ALL_PROCURE_REQUEST: 'procurement/GET_ALL_PROCURE_REQUEST',
  REVERSE_UPLOAD: 'procurement/REVERSE_UPLOAD',
  PROCESS_PROCUREMENT: 'procurement/PROCESS_PROCUREMENT',
  ADD_PROCUREMENT_MARKUP: 'procurement/ADD_PROCUREMENT_MARKUP',
  GET_PROCURE_BACKLOG_HISTORY: 'procurement/GET_PROCURE_BACKLOG_HISTORY',
  BALANCE_RESOLUTION: 'procurement/BALANCE_RESOLUTION',
  UPLOAD_PROCURE_BACKLOG: 'procurement/UPLOAD_PROCURE_BACKLOG',
}

export default actions
