import actions from './actions'

const initialState = {
  loading: false,
  savingTransLoading: false,
  data: {},
  tabKey: "0",
  memberList: [],
  memberDetails: {},
  userBankDetails: {},
  userDetailsId: null,
  closureInfo: [],
  memberSavingDetail: {},
  memberSavingTrans: [],
  memberTargetDetail: {},
  memberTargetTrans: {},
  memberShareDetail: {},
  memberShareTrans: [],
  memberLoanDetail: {},
  memberLoanTrans: {},
  guaranteedLoan:{},
  memberProcurementDetail: {},
  memberProcurementTrans: [],
  memberWalletTrans: [],
  memberWalletBal: 0.00,
  memberWithdrawalWaiver: [],
  memberPackageDebt: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
