const actions = {
  SET_STATE: 'loan/SET_STATE',
  SET_TAB_KEY: 'loan/SET_TAB_KEY',
  GET_ZERO_LOAN_SUMMARY: 'loan/GET_ZERO_LOAN_SUMMARY',
  GET_ALL_LOAN_REQUEST: 'loan/GET_ALL_LOAN_REQUEST',
  GET_ZERO_LOAN_REQUEST: 'loan/GET_ZERO_LOAN_REQUEST',
  GET_LOAN_REPAY_DETAIL: 'loan/GET_LOAN_REPAY_DETAIL',
  GET_ZERO_LOAN_DETAIL: 'loan/GET_ZERO_LOAN_DETAIL',
  GET_ZERO_LOAN_TRANSACTION: 'loan/GET_ZERO_LOAN_TRANSACTION',
  APPROVE_DECLINE_LOAN: 'loan/APPROVE_DECLINE_LOAN',
  PROCESS_LOAN: 'loan/PROCESS_LOAN',
  REFUND_LOAN: 'loan/REFUND_LOAN',
  CLOSE_LOAN: 'loan/CLOSE_LOAN',
}

export default actions
