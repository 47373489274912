/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroProcurementSummary: jwt.getZeroProcurementSummary,
  getZeroProcurementRequests: jwt.getZeroProcurementRequests,
  getProcureRepayDetail: jwt.getProcureRepayDetail,
  getAllProcurement: jwt.getAllProcurement,
  getZeroProcurementRequestDetail: jwt.getZeroProcurementRequestDetail,
  getZeroProcurementTransactions: jwt.getZeroProcurementTransactions,
  approveDeclineProcurement: jwt.approveDeclineProcurement,
  uploadProcumentReversal: jwt.uploadProcumentReversal,
  getTargetTransDetail: jwt.getTargetTransDetail,
  processProcurementRequest: jwt.processProcurementRequest,
  uploadProcureBackLog: jwt.uploadProcureBackLog,
  getProcureBacklog: jwt.getProcureBacklog,
  balanceResolution: jwt.balanceResolution,
  addProcurementMarkup: jwt.addProcurementMarkup
}

export function* GET_ZERO_PROCUREMENT_SUMMARY() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcurementSummary)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        procurementSummary: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_PROCUREMENT_REQUEST() {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcurementRequests)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        pendingProcurementRequest: success?.data?.pending || [],
        approvedProcurementRequest: success?.data?.approved || [],
        processingProcurementRequest: success?.data?.processing || [],
        disbursedProcurementRequest: success?.data?.disbursed || [],
        ongoingProcurementRequest: success?.data?.ongoing || [],
        completedProcurementRequest: success?.data?.completed || [],
        cancelledProcurementRequest: success?.data?.cancelled || [],
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_PROCUREMENT_DETAIL({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcurementRequestDetail, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        procurementDetail: success?.data?.procurement_details,
        procurementAdmin: success?.data?.admins,
        procurementGuarantor: {members: success?.data?.guarantors_member, non_members: success?.data?.guarantors_non_member},
        procurementDetailStatus: success?.data?.procurement_status,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_PROCUREMENT_TRANSACTION({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcurementTransactions, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        procurementTransDetail: {...success?.data?.details, balance: success?.data?.balance},
        procurementTransTransaction: success?.data?.transactions || []
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ALL_PROCURE_REQUEST({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllProcurement, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        allProcureRequest: success?.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_PROCURE_REPAY_DETAIL({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProcureRepayDetail, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        procureRepays: success?.data,
        loanAmount: success?.loan_amt,
        amountRepaid: success?.loan_bal
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* APPROVE_DECLINE_PROCUREMENT({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineProcurement, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_SUMMARY' })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_REQUEST' })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_DETAIL', payload: payload?.procurement_id })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* PROCESS_PROCUREMENT({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.processProcurementRequest, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_SUMMARY' })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_REQUEST' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* ADD_PROCUREMENT_MARKUP({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.addProcurementMarkup, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_SUMMARY' })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_REQUEST' })
    yield put({ type: 'procurement/GET_ZERO_PROCUREMENT_DETAIL', payload: payload?.procurement_id })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* UPLOAD_PROCURE_BACKLOG({ payload }) {
  console.log(payload)
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.uploadProcureBackLog, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    // yield put({ type: 'procurement/GET_ZERO_PENDING_procurement' })
    notification.success({
      message: success.message,
    })
      setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* REVERSE_UPLOAD({ payload }) {
  console.log(payload)
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.uploadProcumentReversal, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        uploadedData: success?.success,
        checkedExcelData: [],
      },
    })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
    yield put({
      type: 'procurement/GET_PROCURE_BACKLOG_HISTORY'
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* BALANCE_RESOLUTION({ payload }) {
  console.log(payload)
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.balanceResolution, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    // yield put({ type: 'procurement/GET_ZERO_PENDING_procurement' })
    notification.success({
      message: success.message,
    })
    //   setTimeout(() => {
    //   window.location.reload()
    // }, 500)
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_PROCURE_BACKLOG_HISTORY({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getProcureBacklog, payload)
  if (success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
        uploadHistory: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'procurement/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SET_TAB_KEY({ payload }) {
  yield put({
    type: 'procurement/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY)])
  yield all([takeEvery(actions.GET_ZERO_PROCUREMENT_SUMMARY, GET_ZERO_PROCUREMENT_SUMMARY)])
  yield all([takeEvery(actions.GET_ZERO_PROCUREMENT_REQUEST, GET_ZERO_PROCUREMENT_REQUEST)])
  yield all([takeEvery(actions.GET_ZERO_PROCUREMENT_DETAIL, GET_ZERO_PROCUREMENT_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_PROCUREMENT_TRANSACTION, GET_ZERO_PROCUREMENT_TRANSACTION)])
  yield all([takeEvery(actions.APPROVE_DECLINE_PROCUREMENT, APPROVE_DECLINE_PROCUREMENT)])
  yield all([takeEvery(actions.GET_PROCURE_REPAY_DETAIL, GET_PROCURE_REPAY_DETAIL)])
  yield all([takeEvery(actions.GET_ALL_PROCURE_REQUEST, GET_ALL_PROCURE_REQUEST)])
  yield all([takeEvery(actions.PROCESS_PROCUREMENT, PROCESS_PROCUREMENT)])
  yield all([takeEvery(actions.REVERSE_UPLOAD, REVERSE_UPLOAD)])
  yield all([takeEvery(actions.ADD_PROCUREMENT_MARKUP, ADD_PROCUREMENT_MARKUP)])
  yield all([takeEvery(actions.GET_PROCURE_BACKLOG_HISTORY, GET_PROCURE_BACKLOG_HISTORY)])
  yield all([takeEvery(actions.BALANCE_RESOLUTION, BALANCE_RESOLUTION)])
  yield all([takeEvery(actions.UPLOAD_PROCURE_BACKLOG, UPLOAD_PROCURE_BACKLOG)])
}
